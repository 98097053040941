import React from 'react';

import DashboardBlock from 'components/organisms/wall/dashboard/DashboardBlock';
import { AMOUNT_OF_DECLARATIONS, DASHBOARD_FIELDS } from 'constants/wall/dashboard';

import componentStyle from 'sass-boilerplate/stylesheets/components/wall/Dashboard.module.scss';

/**
 * organism component used to render list of dashboard data
 * @param kpiData
 * @param selectKpi
 * @param selectedKpi
 * @param isAdmin
 * @constructor
 */
const DashboardList = ({ kpiData, selectKpi, selectedKpi, isAdmin }) => {
  const { dashboardKpis } = componentStyle;

  return (
    <div className={dashboardKpis}>
      {DASHBOARD_FIELDS.map((kpi, index) => {
        if (kpi === AMOUNT_OF_DECLARATIONS && !isAdmin) {
          return null;
        }
        return (
          <DashboardBlock
            key={index}
            {...{
              kpi,
              selectKpi: () => selectKpi(index, kpi),
              selectedKpi,
              kpiData,
              isAdmin
            }}
          />
        );
      })}
    </div>
  );
};

export default DashboardList;
