export const BUTTON_MAIN_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TEXT_ONLY: 'textOnly',
  DISABLED: 'disabled',
  DANGER: 'danger',
  ALT: 'alt',
  WITH_ICON: 'withIcon',
  WITH_ICON_DISABLED: 'withIconDisabled'
};

export const BUTTON_MAIN_VARIANT = {
  NORMAL: 'normal',
  INVERTED: 'inverted'
};

export const LINK_TARGET = {
  BLANK: '_blank'
};

export const LINK_ATTRIBUTE = {
  DOWNLOAD: 'download'
};
