export const TIME_OF_ALLOCATION = 'timeOfAllocation';
export const END_OF_PROGRAM = 'endOfProgram';
export const AGREEMENT_ADMIN = 'agreementAdmin';

export enum POINTS_CONVERSION_STATUS {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED'
}

export const POINTS_CONVERSION_STATUSES = {
  1: POINTS_CONVERSION_STATUS.PENDING,
  2: POINTS_CONVERSION_STATUS.SUCCESS,
  3: POINTS_CONVERSION_STATUS.DECLINED
};

export const SPEND_TYPE = {
  1: TIME_OF_ALLOCATION,
  2: END_OF_PROGRAM,
  3: AGREEMENT_ADMIN
};

export enum AUTOMATIC_POST_TYPE {
  POINTS_CONVERSION_REQUEST = 24,
  SUPER_PLATFORM_CREATED = 25
}

export enum EDIT_POINTS_CONVERSION_STATUS {
  ACCEPT = 'accept',
  DECLINE = 'decline'
}
