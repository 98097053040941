import React, { useState, useRef, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import HeadingAtom from 'components/atoms/ui/Heading';
import style from 'assets/style/components/launch/Launch.module.scss';
import style2 from 'assets/style/components/wall/GeneralWallStructure.module.scss';
import style3 from 'assets/style/common/VericalTabs.module.scss';
import style4 from 'assets/style/components/Ai/AiRagComponent.module.scss'
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import Button from 'components/atoms/ui/Button';
import AIRAGApi from 'api/IA API/AIRagApi';
import Multiselect from 'multiselect-react-dropdown';
import UserApi from 'api/UsersApi';
import { IUserProgramsSearchCriteria } from 'interfaces/api/IUserProgramsSearchCriteria';
import { UserContext } from 'components/App';
import { IadminProgram } from './AiInterface';
import eCardStyle from 'sass-boilerplate/stylesheets/components/launch/Ecard.module.scss';
import { toast } from 'react-toastify';
const questions = [
  'ai.question1',
  'ai.question2',
  'ai.question3',
  'ai.question4',
  'ai.question5',
  'ai.question6',
  'ai.question7'
];

const questionsCOMMON = [
  'ai.common.question1'
];


const AiRagComponent = ({ isHyperAdmin, companyName }) => {
  const [uploadedFiles, setUploadedFiles] = useState(Array(questions.length).fill(null));
  const [uploadedCommonFiles, setUploadedCommonFiles] = useState(Array(questionsCOMMON.length).fill(null));
  const [formSubmitted, setFormSubmitted] = useState(false);
  const fileInputRefs = useRef([]);
  const commonFileInputRefs = useRef([]);
  const { formatMessage } = useIntl();
  const [programOptions, setProgramOptions] = useState < IadminProgram[] > ([]); // Ensure this is always an array
  const [selectedProgram, setSelectedProgram] = useState < IadminProgram > (null); // State to hold selected program
  const { userData } = useContext(UserContext);

  function extractPrograms(platforms) {
    let programs = [];

    function recurse(platforms) {
      platforms.forEach(platform => {
        platform.programs.forEach(program => {
          if (program.iaCompany) {
            const programName = program.name?.trim() + '_' + program.id;
            const name = program.name + " ( " + program.iaCompany[1].iaName + " )";
            programs.push({ id: program.id, name: name, companyName: program.companyName, programName: programName });
          }
        });
        if (platform.subPlatforms && platform.subPlatforms.length > 0) {
          recurse(platform.subPlatforms);
        }
      });
    }

    recurse(platforms);
    return programs;
  }



  useEffect(() => {
    const fetchProgramOptions = async () => {
      if (!userData?.uuid) return; // Ensure userData and uuid are available
      try {
        const userApi = new UserApi();
        const useruuid = String(userData.uuid);
        const searchCriteria: IUserProgramsSearchCriteria = {
          uuid: useruuid,
        };
        const response = await userApi.getAdminPrograms(useruuid, {});
        const result = extractPrograms(response.entries);
        console.log("Hada :" + result)

        setProgramOptions(result); // Ensure response.data is always an array
        // console.log(response);
      } catch (error) {
        console.error('Error fetching user programs:', error);
      }
    };

    fetchProgramOptions();
  }, [userData]);

  const handleProgramSelect = (selectedProgram) => {
    setSelectedProgram(selectedProgram);
    console.log(selectedProgram);
  };


  const handleFileChange = async (index, isCommon, event, question) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const newUploadedCommonFiles = [...uploadedCommonFiles];
      newUploadedCommonFiles[index] = file;
      setUploadedCommonFiles(newUploadedCommonFiles);
      if (selectedProgram) {
        let programName = selectedProgram[0].programName;
        programName = programName?.trim();
        programName = programName?.replaceAll(' ', '_');

        let companyName = selectedProgram[0].companyName;
        companyName = companyName?.trim();
        companyName = companyName?.replaceAll(' ', '_');

        // question = question.trim();
        // question = question.replaceAll('.', '_');

        const userEmail = userData.email;

        const filename = file.name;
        const ext = filename?.split('.')?.pop();

        if (ext == 'pdf' || ext == "txt") {
          let fileName = question + "." + ext;

          switch (question) {
            case questions[0]:
              fileName = 'products' + "." + ext;
              break;

            case questions[1]:
              fileName = 'prices' + "." + ext;
              break;

            case questions[2]:
              fileName = 'technical_manuals' + "." + ext;
              break;

            case questions[3]:
              fileName = 'legals' + "." + ext;
              break;

            case questions[4]:
              fileName = 'compititions' + "." + ext;
              break;

            case questions[5]:
              fileName = 'history' + "." + ext;
              break;

            case questions[6]:
              fileName = 'values' + "." + ext;
              break;

            default:
              break;
          }

          try {
            const response = await AIRAGApi.uploadFiles([file], companyName, programName, isCommon, fileName, userEmail);
            toast(formatMessage({ id: 'toast.message.ai.fileSavedForIndexation'}, { filename: filename } ));
          } catch (error) {
            toast(formatMessage({ id: 'toast.message.ai.error.indextionFailled'}, { filename: filename }));
          }
        } else {
          toast(formatMessage({ id: 'toast.message.ai.fileTypeNotSupportedYet' }));
        }
      } else {
        toast(formatMessage({ id: 'toast.message.ai.noProgramSelected' }));
      }
    }
  };

  return (
    <React.Fragment>
      <div style={{ marginLeft: '50px' }}>
        <HeadingAtom className={style4.title} size="3" textId="ai.title.label" />
      </div>
      <div className={`aiSubmitText ${style4.contentWrapper} ${style4.textIntro}`}>
        <FormattedMessage id="ai.submit.text" />
      </div>
      <div className={style4.flashySlogan}>
        <FormattedMessage id="yooowin.slogan" />
      </div>

      <form>
        <div className={style4.multiContainer}>
          <Multiselect
            style={{ overflowY: "hidden" }}
            key="course-select"
            options={programOptions} // Options to display in the dropdown
            onSelect={handleProgramSelect}
            onRemove={() => setSelectedProgram(null)}
            selectedValues={selectedProgram}
            displayValue="name" // Property name to display in the dropdown options
            placeholder={formatMessage({ id: 'aiTunnel.ai.placeholder' })}
            className={`input-group ${eCardStyle.customMultiselect}`}
            showCheckbox={false}
            showArrow={true}
            customArrow={true}
            singleSelect={true}
          />
        </div>

        <div>
          <div className={style4.questionsWrapper}>
            {isHyperAdmin && questionsCOMMON.map((question, index) => (
              <div key={question}>
                <FormattedMessage id={question}>
                  {(text) => (
                    <div className={style4.questionContainer}>
                      <label className={style4.label}>{text}</label>
                      <input
                        type="file"
                        onChange={(event) => handleFileChange(index, true, event, 'common')}
                        ref={(el) => (commonFileInputRefs.current[index] = el)}
                        className={style4.inputFile}
                      />
                    </div>
                  )}
                </FormattedMessage>
              </div>
            ))}
          </div>
          <div className={style4.questionsColumns}>
            <div className={style4.leftColumn}>
              {selectedProgram && questions.slice(0, Math.ceil(questions.length / 2)).map((question, index) => (
                <div key={question}>
                  <FormattedMessage id={question}>
                    {(text) => (
                      <div className={style4.questionContainer}>
                        <label className={style4.AiRagQuestionLabel}>{text}</label>
                        <input
                          type="file"
                          onChange={(event) => handleFileChange(index, false, event, question)}
                          ref={(el) => (fileInputRefs.current[index] = el)}
                          className={style4.inputFile}
                        />
                      </div>
                    )}
                  </FormattedMessage>
                </div>
              ))}
            </div>
            <div className={style4.rightColumn}>
              {selectedProgram && questions.slice(Math.ceil(questions.length / 2)).map((question, index) => (
                <div key={question}>
                  <FormattedMessage id={question}>
                    {(text) => (
                      <div className={style4.questionContainer}>
                        <label className={style4.AiRagQuestionLabel}>{text}</label>
                        <input
                          type="file"
                          onChange={(event) => handleFileChange(index + Math.ceil(questions.length / 2), false, event, question)}
                          ref={(el) => (fileInputRefs.current[index + Math.ceil(questions.length / 2)] = el)}
                          className={style4.inputFile}
                        />
                      </div>
                    )}
                  </FormattedMessage>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className={style3.productsWrapperSubmitBtn} style={{ marginBottom: 10 }}>
          <DynamicFormattedMessage
            tag={Button}
            id="form.submit.button"
          />
        </div> */}
      </form>
    </React.Fragment>
  );
}

export default AiRagComponent;

