import { ERROR_CODES, REGISTER_PAGE_STEPS, REGISTER_PAGES } from 'constants/onboarding/general';

export const ONBOARDING_BENEFICIARY_REGISTER_STEPS = [
  {
    component: REGISTER_PAGE_STEPS.TITLE,
    index: REGISTER_PAGE_STEPS.TITLE,
    name: REGISTER_PAGES.TITLE,
    fields: [REGISTER_PAGES.TITLE],
    errorCodes: []
  },
  {
    component: REGISTER_PAGE_STEPS.NAME,
    index: [REGISTER_PAGES.FIRST_NAME],
    name: REGISTER_PAGES.FIRST_NAME,
    fields: [REGISTER_PAGES.FIRST_NAME, REGISTER_PAGES.LAST_NAME],
    errorCodes: []
  },
  {
    component: REGISTER_PAGE_STEPS.AVATAR,
    index: [REGISTER_PAGES.CROPPED_AVATAR],
    name: REGISTER_PAGES.CROPPED_AVATAR,
    fields: [REGISTER_PAGES.CROPPED_AVATAR],
    errorCodes: [ERROR_CODES.INVALID_FILE, ERROR_CODES.FILE_SIZE_TOO_LARGE]
  },
  {
    component: REGISTER_PAGE_STEPS.EMAIL,
    index: REGISTER_PAGE_STEPS.EMAIL,
    name: REGISTER_PAGES.EMAIL,
    fields: [REGISTER_PAGES.EMAIL],
    errorCodes: [ERROR_CODES.DUPLICATE_VALUE]
  },
  {
    component: REGISTER_PAGE_STEPS.PASSWORD,
    index: REGISTER_PAGE_STEPS.PASSWORD,
    name: REGISTER_PAGES.PASSWORD,
    fields: [REGISTER_PAGES.PASSWORD, REGISTER_PAGES.PASSWORD_CONFIRMATION],
    errorCodes: [ERROR_CODES.PASSWORD_MATCH]
  }
];
