import React from 'react';
import { useIntl } from 'react-intl';

import style from 'assets/style/common/Input.module.scss';
import { DEFAULT_INPUT_CHAR_LENGTH } from 'constants/general';

/**
 * Atom component used to render create new input product field
 *
 * @param value
 * @param onChange
 * @param type
 * @param maxLength
 * @constructor
 */
const CreateNewInputField = ({ value, onChange, type, maxLength = DEFAULT_INPUT_CHAR_LENGTH }) => {
  const { formatMessage } = useIntl();
  const { container, defaultInputStyle } = style;

  return (
    <div className={container}>
      <input
        className={defaultInputStyle}
        {...{ onChange, value }}
        placeholder={formatMessage({ id: `launchProgram.field.name.${type}` })}
        maxLength={maxLength}
      />
    </div>
  );
};

export default CreateNewInputField;
