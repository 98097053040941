export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';
export const SET_PRICING_DATA = 'SET_PRICING_DATA';

export const SET_MODAL_STATE = 'SET_MODAL_STATE';

// User details
export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
export const SET_HANDLE_REDIRECT_ON_LOGIN = 'SET_HANDLE_REDIRECT_ON_LOGIN';

// Quick launch
export const SET_LAUNCH_STEP_DATA = 'SET_LAUNCH_STEP_DATA';
export const SET_MULTIPLE_STEP_DATA = 'SET_MULTIPLE_STEP_DATA';
export const RESET_STEP_DATA = 'RESET_STEP_DATA';
export const CREATE_NEW_GOAL = 'CREATE_NEW_GOAL';
export const DELETE_GOAL = 'DELETE_GOAL';

// Wall
export const SET_PROGRAMS = 'SET_PROGRAMS';
export const SET_PLATFORMS = 'SET_PLATFORMS';
export const SET_SUPER_PLATFORMS = 'SET_SUPER_PLATFORMS';
export const SET_ACTIVE_PLATFORM = 'SET_ACTIVE_PLATFORM';
export const SET_USER_RANKINGS = 'SET_USER_RANKINGS';
export const SET_FORCED_PROGRAM = 'SET_FORCED_PROGRAM';
export const SET_REDIRECT_DATA = 'SET_ON_REDIRECT_DATA';
export const SET_ACTIVE_PROGRAM = 'SET_ACTIVE_PROGRAM';
export const SET_PROGRAM_USERS = 'SET_PROGRAM_USERS';
export const SET_IS_PROGRAM_SELECTION_LOCKED = 'SET_IS_PROGRAM_SELECTION_LOCKED';
export const SET_STORE_DATA = 'SET_STORE_DATA';
export const RELOAD_AGENDA = 'RELOAD_AGENDA';
export const SET_POST_CREATED = 'SET_POST_CREATED';
export const SET_LINKED_EMAILS_DATA = 'SET_LINKED_EMAILS_DATA';
export const SET_LOADING_PLATFORMS = 'SET_LOADING_PLATFORMS';
export const SET_BENEFICIARY_POINTS = 'SET_BENEFICIARY_POINTS';
export const SET_PROGRAM_DETAILS = 'SET_PROGRAM_DETAILS';
export const SET_SELECTED_PLATFORM = 'SET_SELECTED_PLATFORM';

// User declarations
export const SET_USER_DECLARATION_LIST_SORTING = 'SET_USER_DECLARATION_LIST_SORTING';

//Onboarding
export const SET_ONBOARDING_REGISTER_DATA = 'SET_ONBOARDING_REGISTER_DATA';
