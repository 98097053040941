import axiosInstance from 'config/axiosConfig';
import { AUTHORIZATION, PROGRAMS } from 'constants/api';

class ProgramsApi {
  getProgramDetails = async (programId: number) => {
    const { data } = await axiosInstance().get(`/${PROGRAMS}/${programId}`);

    return data;
  };

  getAnonymousProgramDetails = async (programId: number) => {
    const { data } = await axiosInstance().get(`/${PROGRAMS}/${programId}`, {
      params: { view: 'anonymous' },
      headers: { [AUTHORIZATION]: '' }
    });

    return data;
  };
}

export default ProgramsApi;
