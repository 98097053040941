export enum PLATFORM_INVITE_ROLE {
  ADMIN = 1,
  MANAGER = 2
}

export enum PLATFORM_ROLE_OPERATION {
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum PLATFORM_INVITATION_STATUS {
  PENDING = 2
}
