export const customStyles = {
  option: () => ({
    borderBottom: '1px dotted pink',
    padding: 15
  }),
  control: () => ({
    width: 140,
    display: 'flex'
  }),
  menuList: () => ({
    color: '#000000'
  }),
  singleValue: () => ({ transition: 'opacity 300ms', width: '95%' })
};

export const onboardingCustomStyles = {
  ...customStyles,
  dropdownIndicator: provided => ({ ...provided, color: '#fff !important', '& svg': { fill: '#fff' } })
};
