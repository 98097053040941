import { EMAIL_TOKEN_EXPIRED_LINK_ROUTE, RESET_PASSWORD_EXPIRED_LINK_ROUTE } from 'constants/routes';

export enum OPERATION_TYPES {
  ACTIVATE_EMAIL = 'activate',
  RESEND_EMAIL = 'resend'
}

export enum VALIDATE_TYPE {
  RESET_PASSWORD = 1,
  EMAIL_CONFIRMATION = 2
}

export const VALIDATE_TYPE_ERROR_REDIRECT = {
  [VALIDATE_TYPE.RESET_PASSWORD]: RESET_PASSWORD_EXPIRED_LINK_ROUTE,
  [VALIDATE_TYPE.EMAIL_CONFIRMATION]: EMAIL_TOKEN_EXPIRED_LINK_ROUTE
};
