import React from 'react';
import { useIntl } from 'react-intl';

import LeftSideLayout from 'components/organisms/layouts/LeftSideLayout';
import Loading from 'components/atoms/ui/Loading';
import MetricBlock from 'components/molecules/hyperadmin/MetricBlock';
import MetricBlockWithList from 'components/molecules/hyperadmin/MetricBlockWithList';
import {
  LOADER_TYPE,
  TOTAL_EMAILS_SENT,
  TOTAL_REVENUE_SUBSCRIPTIONS,
  TOTAL_SIZE_OF_DOCUMENTS,
  TYPES_OF_PROGRAMS,
  WALL_TYPE
} from 'constants/general';
import { useMetrics } from 'hooks/hyperAdmin/useMetrics';
import { NUMBER } from 'constants/validation';

import style from 'sass-boilerplate/stylesheets/components/hyperadmin/Metrics.module.scss';

/**
 * Page component used to render metrics page
 *
 * @constructor
 */
const MetricsPage = () => {
  const { metricsData } = useMetrics();
  const { formatMessage } = useIntl();

  if (!metricsData) return <Loading type={LOADER_TYPE.PAGE} />;

  return (
    <LeftSideLayout theme={WALL_TYPE} hasUserIcon>
      <div className={style.metricsContainer}>
        {Object.keys(metricsData).map(key => {
          if (typeof metricsData[key] === NUMBER) {
            return <MetricBlock titleKey={key} value={metricsData[key]} key={key} />;
          }

          if (key === TYPES_OF_PROGRAMS) {
            return <MetricBlockWithList titleKey={key} value={metricsData[key]} key={key} />;
          }

          if (key === TOTAL_REVENUE_SUBSCRIPTIONS || key === TOTAL_EMAILS_SENT || key === TOTAL_SIZE_OF_DOCUMENTS) {
            return (
              <MetricBlock
                titleKey={key}
                value={formatMessage({ id: 'metrics.comingSoon' })}
                key={key}
                comingSoonContent={true}
              />
            );
          }

          return <MetricBlock titleKey={key} value={!metricsData[key] && '-'} key={key} />;
        })}
      </div>
    </LeftSideLayout>
  );
};

export default MetricsPage;
