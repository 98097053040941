/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';

import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import GeneralBlock from 'components/molecules/block/GeneralBlock';
import { HTML_TAGS, IMAGES_ALT, LOADER_TYPE } from 'constants/general';
import { useCheckAndRedirect } from 'hooks/user/useCheckAndRedirect';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';

import card from 'assets/images/wall/gift_card.png';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import wallStyle from 'sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss';
import wallBaseStyle from 'sass-boilerplate/stylesheets/components/wall/WallBaseBlock.module.scss';
import widgetStyle from 'sass-boilerplate/stylesheets/components/wall/widgets/Widget.module.scss';
// import blockStyle from 'sass-boilerplate/stylesheets/components/block/Block.module.scss';
import { FREEMIUM } from 'constants/routes';
import { WALL_BLOCK } from 'constants/wall/blocks';
import { PROGRAM_TYPES, PROGRAM_TYPES_NAMES } from 'constants/wall/launch';
import { useWallSelection } from 'hooks/wall/useWallSelection';
import { getHtmlFromEditorBlocks } from 'services/WysiwygService';
import { getCurrentProgramDetails } from 'services/wall/blocks';
import useSelectedProgram from 'hooks/wall/useSelectedProgram';
import Loading from 'components/atoms/ui/Loading';
import style from 'sass-boilerplate/stylesheets/components/wall/WallUserBlock.module.scss';
import MediaBlock from '../postBlock/media/MediaBlock';
import postTabStyle from 'sass-boilerplate/stylesheets/components/wall/PostTabs.module.scss';
// import useDashboardBeneficiaryPoints from 'hooks/wall/dashboard/useDashboardBeneficiaryData';
import EcardPage from 'components/pages/EcardPage';
import Button from 'components/atoms/ui/Button';
import FlexibleModalContainer from 'containers/FlexibleModalContainer';
import eCardStyle from 'sass-boilerplate/stylesheets/components/launch/Ecard.module.scss';
import stylePost from 'sass-boilerplate/stylesheets/components/wall/PostMedia.module.scss';
import ConvertEcardsModal from 'components/pages/wall/ConvertEcardsModal';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
/**
 * Organism component used to render payment widget
 *
 * @constructor
 */
const PaymentWidget = ({
  isAdmin,
  programDetails,
  modifyProgramDesign,
  platformProgramsPointsList,
  id,
  selectedProgramIdParam,
  disabled
}) => {
  const {
    withBoldFont,
    withGrayAccentColor,
    textCenter,
    displayInlineBlock,
    pointer,
    imgFluid,
    maxWidth20,
    mb2,
    widthFull
  } = coreStyle;
  const { widgetTitle, widgetTextBody, widgetPayment } = widgetStyle;
  const [canConvertPay, setCanConvertPay] = useState(false);
  const [pointsPay, setPointsPay] = useState(0);
  const { colorMainButtons, colorWidgetTitle } = useSelectedProgramDesign();
  const { onRewardsRedirect } = useCheckAndRedirect();
  const { customEcardModal, customModal, marginTopImp0 } = eCardStyle;

  //Modify Widget blocs to pub in walls only and when a wall is selected --> not in show all programs
  const selectedProgram = useSelectedProgram();
  const { selectedProgramIndex, programs, selectedProgramId } = useWallSelection();
  const [showModal, setShowModal] = useState(false);
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);
  const currentProgramDetails = getCurrentProgramDetails(programDetails, WALL_BLOCK.PAYMENT_BLOCK);
  const { userBlockCount, userBlockTitle } = style;
  const { menuTitle, pictureUrl, content, bannerTitle } = currentProgramDetails;
  const isFreemium = selectedProgram && selectedProgram.programType === PROGRAM_TYPES[FREEMIUM];
  const programType = programDetails?.type || PROGRAM_TYPES[FREEMIUM];
  const { formatMessage } = useIntl();
  const isAllLoaded =
    (programs.length > 1 && selectedProgramIndex == 0) || programs.length == 1
      ? true
      : Object.keys(programDetails).length > 0;
  //Add Bloc title and desc for social wall  --> freemium
  let blocDesc = 'wall.user.see.all';
  let imagExt = 'jpg';
  let blockTitle = 'wall.rewards.title';
  if (selectedProgramIndex != 0 && isFreemium && isAllLoaded) {
    blockTitle = menuTitle;
    blocDesc = getHtmlFromEditorBlocks(content);
    imagExt = pictureUrl.substring(pictureUrl?.lastIndexOf('.') + 1) || 'jpg';
  }
  //For the image
  const src = isAllLoaded ? pictureUrl : '';
  const { postIcon, modifyProgram, modifyPayBlock } = postTabStyle;
  const onClick = () => {
    if(selectedProgram && selectedProgram.programType){
      const programType = PROGRAM_TYPES_NAMES[selectedProgram.programType]
      modifyProgramDesign(WALL_BLOCK.PAYMENT_BLOCK, programType);
    }
  };

  // Points conversion section

  useEffect(() => {
    console.log('platformProgramsPointsList : ');
    console.log(platformProgramsPointsList);
    const filteredList =
      platformProgramsPointsList.length > 0
        ? platformProgramsPointsList.filter(platform => platform.platformId === id)
        : [];
    console.log('filteredList : ' + filteredList?.toString());
    const filtredProg: any =
      filteredList.length > 0 ? filteredList.filter(program => program.id === selectedProgramId) : [];
    if (filtredProg.length > 0) {
      console.log('filtredProg : ' + filtredProg?.toString());
      const { points, name, converted, cube, canConvert } = filtredProg[0];
      setCanConvertPay(canConvert || false);
      setPointsPay(points || 0);
      setTimeout(() => {}, 500);
    }
  }, [selectedProgramId, platformProgramsPointsList]);

  const onRewardsRedirectClick = isClose => {
    // if(canConvertPay && pointsPay >= 250){
    setShowGiftCardModal(isClose);
    if (!isClose) {
      onRewardsRedirect(true, false);
    }
    // }
  };



  return (
    <GeneralBlock className={`${withGrayAccentColor} ${widgetPayment} ${textCenter} ${wallStyle.hideBlockMobile}`}>
      {!isFreemium && <DynamicFormattedMessage
        className={`${withBoldFont} ${widgetTitle}`}
        style={{ color: colorWidgetTitle }}
        id="wall.paymentWidget.title"
        tag={HTML_TAGS.SPAN}
      />}
      {!isAllLoaded && (
        <div className={userBlockCount}>
          <Loading className={coreStyle.withSecondaryColor} type={LOADER_TYPE.DROPZONE} />
        </div>
      )}
      {isAllLoaded && (
        <>
          {(selectedProgramIndex === 0 || !isFreemium) && (
            <>
              <img
                className={`${mb2} ${displayInlineBlock} ${textCenter} ${imgFluid} ${wallBaseStyle.paymentBlockImg}`}
                src={card}
                alt={IMAGES_ALT.CARD}
                onClick={disabled? ()=>toast(formatMessage({ id: "toast.message.noWinsClaimHere"})):() => onRewardsRedirectClick(true)}
              />
              <FlexibleModalContainer
                fullOnMobile={false}
                className={`${style.mediaModal} ${customEcardModal}`}
                closeModal={() => onRewardsRedirectClick(false)}
                isModalOpen={showGiftCardModal}
              >
                <div className={`${widthFull}`}>
                  <ConvertEcardsModal isConversionEcard={true} onRewardsRedirectClick={onRewardsRedirectClick} canConvert={canConvertPay}></ConvertEcardsModal>                                 
                </div>
              </FlexibleModalContainer>
            </>
          )}
          {selectedProgramIndex != 0 && isFreemium && (
            <>
              <div className={`${wallBaseStyle.modifyBlockContainer}`}>
                <span className={`${userBlockTitle}`} id={blockTitle} style={{ color: colorWidgetTitle }}>
                  {blockTitle}
                </span>
                {isAdmin && (
                  <div className={`${postIcon} ${modifyProgram} ${modifyPayBlock} ${pointer}`} onClick={onClick} />
                )}
              </div>

              <MediaBlock
                mediaType="image"
                showModal={false}
                setShowModal={setShowModal}
                media={{
                  url: src, //file and video
                  ext: imagExt,
                  src: src, //src for image
                  size: 100,
                  title: 'Bloc image file',
                  alt: 'Bloc image file'
                }}
              />
              <div className={`${coreStyle.withGrayColor}`} dangerouslySetInnerHTML={{ __html: blocDesc }} />
            </>
          )}
        </>
      )}
    </GeneralBlock>
  );
};
export default PaymentWidget;
