import React from 'react';
import { useHistory } from 'react-router-dom';

import GeneralBlock from 'components/molecules/block/GeneralBlock';
import Loading from 'components/atoms/ui/Loading';
import useUserRankings from 'hooks/wall/blocks/useUserRankings';
import { WALL_BENEFICIARY_RANKING_ROUTE } from 'constants/routes';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import RankingIcon from 'components/atoms/ui/RankingIcon';
import { HTML_TAGS, LOADER_TYPE } from 'constants/general';
import { useSelectedProgramDesign } from 'hooks/wall/ui/useSelectedProgramColors';

import style from 'sass-boilerplate/stylesheets/components/wall/WallUserBlock.module.scss';
import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import wallStyle from 'sass-boilerplate/stylesheets/components/wall/WallBasePageStructure.module.scss';
import useSelectedProgram from '../../../../hooks/wall/useSelectedProgram';
import { FREEMIUM, PROGRAM_TYPES } from '../../../../constants/wall/launch';

/**
 * Molecule component used to render user block
 * @constructor
 */
const UserRankingsWidget = () => {
  const { userBlock, userBlockTitle, userBlockLink, userBlockCount, userBlockRanking, userBlockMatrix } = style;
  const { withSecondaryColor, pointer, widthFull, withGrayAccentColor, withFontMedium, overlayBeforeWhite } = coreStyle;
  const { colorMainButtons, colorSidebar } = useSelectedProgramDesign();
  const { isLoading, selectedRanking } = useUserRankings();
  const selectedProgram = useSelectedProgram();
  const isFreemium = selectedProgram && selectedProgram.programType === PROGRAM_TYPES[FREEMIUM];

  const history = useHistory();
  const onSelectWidget = () => history.push(WALL_BENEFICIARY_RANKING_ROUTE);
  const hasRanking = (!isLoading && selectedRanking.rank) || !isFreemium;

  return (
    <GeneralBlock
      tooltipId="disabledWidget"
      className={`${userBlock} ${userBlockMatrix} ${userBlockRanking} ${wallStyle.hideBlockMobile} ${isFreemium &&
        overlayBeforeWhite}`}
    >
      <DynamicFormattedMessage
        className={`${userBlockTitle} ${widthFull}`}
        id="wall.widget.user.ranking.title"
        tag={HTML_TAGS.P}
      />
      {isLoading && (
        <div className={userBlockCount}>
          <Loading className={withSecondaryColor} type={LOADER_TYPE.DROPZONE} />
        </div>
      )}
      {hasRanking && (
        <p className={userBlockCount} style={{ color: colorSidebar }}>
          {selectedRanking.rank}
        </p>
      )}
      {isFreemium ? (
        <DynamicFormattedMessage
          className={`${withGrayAccentColor} ${withFontMedium}`}
          tag={HTML_TAGS.DIV}
          id="wall.widget.user.ranking.noRanking"
        />
      ) : (
        <RankingIcon fillColor={colorSidebar} hasRanking />
      )}
      {hasRanking ? (
        <DynamicFormattedMessage
          onClick={onSelectWidget}
          className={`${hasRanking ? userBlockLink : ''} ${pointer} ${widthFull} `}
          id="wall.widget.user.ranking.see.all"
          tag={HTML_TAGS.DIV}
          style={{ color: colorMainButtons }}
        />
      ) : (
        !isLoading && (
          <DynamicFormattedMessage
            className={widthFull}
            id="wall.widget.user.ranking.none"
            tag={HTML_TAGS.DIV}
            style={{ color: colorMainButtons }}
          />
        )
      )}
    </GeneralBlock>
  );
};

export default UserRankingsWidget;
