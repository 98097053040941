export const NOTIFICATION_ID_TYPE = {
  NEW_PEOPLE_MANAGER: 'newPeopleManager',
  BLOCKED_FROM_PROGRAM: 'blockedFromProgram',
  DECLARATION_VALIDATED: 'declarationValidated',
  DECLARATION_REJECTED: 'declarationRejected',
  FIRST_DECLARATION_ON_PROGRAM: 'firstDeclaration',
  PROGRAM_HAS_100_DECLARATIONS: 'programHas100Declarations',
  PROGRAM_HAS_1000_DECLARATIONS: 'programHas1000Declarations',
  PLATFORM_ROLE_UPDATED: 'platformRoleUpdate',
  TYPE_SUBSCRIPTION_PAYMENT: 'subscriptionPayment',
  NEW_PARTICIPANT_JOINED: 'newParticipant',
  PROGRAM_HAS_STARTED: 'adminProgramStarted',
  POINTS_CONVERTED: 'pointsConverted',
  PLAN_LIMIT_REACHED: 'planLimitReached',
  POINT_CONVERSION_VALIDATED: 'pointsConversionValidated',
  NEW_SECURITY_ROLE: 'newSecurityRole'
};

export enum NOTIFICATION_CATEGORY {
  ALL = 'All',
  COMMUNITY = 1,
  ACTIONS = 2,
  RANKING = 3,
  REWARDS = 4,
  GOALS = 5,
  ROLES = 6
}

export const NOTIFICATION_GROUPS = {
  ALL: { value: 'all', id: NOTIFICATION_CATEGORY.ALL },
  COMMUNITY: { value: 'community', id: NOTIFICATION_CATEGORY.COMMUNITY },
  ACTIONS: { value: 'actions', id: NOTIFICATION_CATEGORY.ACTIONS },
  GOALS: { value: 'goals', id: NOTIFICATION_CATEGORY.GOALS },
  RANKING: { value: 'ranking', id: NOTIFICATION_CATEGORY.RANKING },
  REWARDS: { value: 'rewards', id: NOTIFICATION_CATEGORY.REWARDS },
  ROLES: { value: 'newSecurityRole', id: NOTIFICATION_CATEGORY.ROLES }
};

enum NOTIFICATION_TYPE {
  ADD_COMMENT = 1,
  EDIT_POST = 2,
  ADD_LIKE = 3,
  ACTIONS = 4,
  RANKING = 5,
  ADMIN_PROGRAM_END = 6,
  USERS_PROGRAM_END = 7,
  NEW_SECURITY_ROLE = 8,
  NEW_PEOPLE_MANAGER = 9,
  BLOCKED_FROM_PROGRAM = 10,
  DECLARATION_VALIDATED = 11,
  DECLARATION_REJECTED = 12,
  FIRST_DECLARATION_ON_PROGRAM = 13,
  PROGRAM_HAS_100_DECLARATIONS = 14,
  PROGRAM_HAS_1000_DECLARATIONS = 15,
  POINTS_WERE_AWARDED = 16,
  PLATFORM_ROLE_UPDATED = 17,
  TYPE_SUBSCRIPTION_PAYMENT = 18,
  NEW_PARTICIPANT_JOINED = 19,
  PROGRAM_HAS_STARTED = 20,
  POINTS_CONVERTED = 21,
  PLAN_LIMIT_REACHED = 22,
  POINT_CONVERSION_VALIDATED = 23
}

export enum NOTIFICATION_STATUS {
  UNREAD = 1,
  READ = 2
}

export const NOTIFICATIONS_DROPDOWN_LIMIT = 5;

export const NOTIFICATION_FORMAT_DATE = 'DD/MM/YY';
export const NOTIFICATION_FORMAT_TIME = 'HH[h]mm';

const mapCombination = (category, type, id) => ({ category, type, id });

export const NOTIFICATION = {
  ADD_COMMENTS: mapCombination(NOTIFICATION_CATEGORY.COMMUNITY, NOTIFICATION_TYPE.ADD_COMMENT, 'comment'),
  ADD_LIKE: mapCombination(NOTIFICATION_CATEGORY.COMMUNITY, NOTIFICATION_TYPE.ADD_LIKE, 'like'),
  EDIT_POST: mapCombination(NOTIFICATION_CATEGORY.COMMUNITY, NOTIFICATION_TYPE.EDIT_POST, 'confidentiality'),
  ACTIONS: mapCombination(NOTIFICATION_CATEGORY.ACTIONS, NOTIFICATION_TYPE.ACTIONS, 'actions'),
  RANKING: mapCombination(NOTIFICATION_CATEGORY.RANKING, NOTIFICATION_TYPE.RANKING, 'ranking'),
  REWARDS: mapCombination(NOTIFICATION_CATEGORY.REWARDS, NOTIFICATION_TYPE.ADMIN_PROGRAM_END, 'rewards'),
  USER_GOALS: mapCombination(NOTIFICATION_CATEGORY.GOALS, NOTIFICATION_TYPE.USERS_PROGRAM_END, 'userGoals'),
  NEW_SECURITY_ROLE: mapCombination(
    NOTIFICATION_CATEGORY.ROLES,
    NOTIFICATION_TYPE.NEW_SECURITY_ROLE,
    'newSecurityRole'
  ),
  NEW_PEOPLE_MANAGER: mapCombination(
    NOTIFICATION_CATEGORY.ROLES,
    NOTIFICATION_TYPE.NEW_PEOPLE_MANAGER,
    NOTIFICATION_ID_TYPE.NEW_PEOPLE_MANAGER
  ),
  BLOCKED_FROM_PROGRAM: mapCombination(
    NOTIFICATION_CATEGORY.ROLES,
    NOTIFICATION_TYPE.BLOCKED_FROM_PROGRAM,
    NOTIFICATION_ID_TYPE.BLOCKED_FROM_PROGRAM
  ),
  DECLARATION_VALIDATED: mapCombination(
    NOTIFICATION_CATEGORY.ACTIONS,
    NOTIFICATION_TYPE.DECLARATION_VALIDATED,
    NOTIFICATION_ID_TYPE.DECLARATION_VALIDATED
  ),
  DECLARATION_REJECTED: mapCombination(
    NOTIFICATION_CATEGORY.ACTIONS,
    NOTIFICATION_TYPE.DECLARATION_REJECTED,
    NOTIFICATION_ID_TYPE.DECLARATION_REJECTED
  ),
  FIRST_DECLARATION_ON_PROGRAM: mapCombination(
    NOTIFICATION_CATEGORY.GOALS,
    NOTIFICATION_TYPE.FIRST_DECLARATION_ON_PROGRAM,
    NOTIFICATION_ID_TYPE.FIRST_DECLARATION_ON_PROGRAM
  ),
  PROGRAM_HAS_100_DECLARATIONS: mapCombination(
    NOTIFICATION_CATEGORY.GOALS,
    NOTIFICATION_TYPE.PROGRAM_HAS_100_DECLARATIONS,
    NOTIFICATION_ID_TYPE.PROGRAM_HAS_100_DECLARATIONS
  ),
  PROGRAM_HAS_1000_DECLARATIONS: mapCombination(
    NOTIFICATION_CATEGORY.GOALS,
    NOTIFICATION_TYPE.PROGRAM_HAS_1000_DECLARATIONS,
    NOTIFICATION_ID_TYPE.PROGRAM_HAS_1000_DECLARATIONS
  ),
  POINTS: mapCombination(NOTIFICATION_CATEGORY.REWARDS, NOTIFICATION_TYPE.POINTS_WERE_AWARDED, 'points'),
  NEW_PLATFORM_ROLE: mapCombination(
    NOTIFICATION_CATEGORY.ROLES,
    NOTIFICATION_TYPE.PLATFORM_ROLE_UPDATED,
    NOTIFICATION_ID_TYPE.PLATFORM_ROLE_UPDATED
  ),
  SUBSCRIPTION_PAYMENT: mapCombination(
    NOTIFICATION_CATEGORY.ACTIONS,
    NOTIFICATION_TYPE.TYPE_SUBSCRIPTION_PAYMENT,
    NOTIFICATION_ID_TYPE.TYPE_SUBSCRIPTION_PAYMENT
  ),
  NEW_PARTICIPANT: mapCombination(
    NOTIFICATION_CATEGORY.COMMUNITY,
    NOTIFICATION_TYPE.NEW_PARTICIPANT_JOINED,
    NOTIFICATION_ID_TYPE.NEW_PARTICIPANT_JOINED
  ),
  PROGRAM_HAS_STARTED: mapCombination(
    NOTIFICATION_CATEGORY.GOALS,
    NOTIFICATION_TYPE.PROGRAM_HAS_STARTED,
    NOTIFICATION_ID_TYPE.PROGRAM_HAS_STARTED
  ),
  POINTS_CONVERTED: mapCombination(
    NOTIFICATION_CATEGORY.ACTIONS,
    NOTIFICATION_TYPE.POINTS_CONVERTED,
    NOTIFICATION_ID_TYPE.POINTS_CONVERTED
  ),
  PLAN_LIMIT_REACHED: mapCombination(
    NOTIFICATION_CATEGORY.ACTIONS,
    NOTIFICATION_TYPE.PLAN_LIMIT_REACHED,
    NOTIFICATION_ID_TYPE.PLAN_LIMIT_REACHED
  ),
  POINT_CONVERSION_VALIDATED: mapCombination(
    NOTIFICATION_CATEGORY.ACTIONS,
    NOTIFICATION_TYPE.POINT_CONVERSION_VALIDATED,
    NOTIFICATION_ID_TYPE.POINT_CONVERSION_VALIDATED
  )
};
