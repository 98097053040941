import { IRadioButtonGroupProps } from 'interfaces/forms/IRadioButtonGroup';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import style from 'assets/style/common/Input.module.scss';

export const RadioButtonGroup: FC<IRadioButtonGroupProps> = ({ label, children }) => {
  const { container, groupLabel } = style;

  return (
    <div className={container}>
      {children}
      {label && (
        <label className={groupLabel}>
          <FormattedMessage id={label} />
        </label>
      )}
    </div>
  );
};
