import React from 'react';
import { useIntl } from 'react-intl';

import style from 'sass-boilerplate/stylesheets/pages/DesignIdentification.module.scss';

/**
 * Atom component used to render create new textarea field
 *
 * @param value
 * @param onChange
 * @param type
 * @param customClass
 * @constructor
 */
const CreateNewTextareaField = ({ value, onChange, type, customClass = style.designTextarea }) => {
  const { formatMessage } = useIntl();

  return (
    <textarea
      className={customClass}
      {...{ onChange, value }}
      placeholder={formatMessage({ id: `launchProgram.field.name.${type}` })}
    />
  );
};

export default CreateNewTextareaField;
