import React from 'react';

import TopControlsList from 'components/molecules/wall/TopControlsList';

import style from 'assets/style/components/wall/TopNavigation.module.scss';

/**
 * Molecule component used to render wall top navigation
 *
 * @constructor
 */
const WallTopNavigation = () => (
  <div className={style.topNavigationWrapper}>
    <TopControlsList />
  </div>
);

export default WallTopNavigation;
