import React, { ReactElement } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import GeneralBlock from 'components/molecules/block/GeneralBlock';
import { numberWithSpaces } from 'utils/general';

import style from 'sass-boilerplate/stylesheets/components/hyperadmin/Metrics.module.scss';
import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { HTML_TAGS } from 'constants/general';

/**
 * Molecule component used to render metric block
 *
 * @param titleKey
 * @param value
 * @param comingSoonContent
 *
 * @constructor
 */
const MetricBlock = ({ titleKey, value, comingSoonContent = false }) => {
  let currency: string | ReactElement = '';
  let contentClass = style.singleMetricValue;
  if (comingSoonContent) {
    contentClass = style.singleMetricComingSoon;
  }

  if (titleKey === 'totalPointsInValue') {
    currency = <DynamicFormattedMessage id="label.euro" tag={HTML_TAGS.SPAN} />;
  }

  return (
    <GeneralBlock className={style.singleMetric}>
      <p className={style.singleMetricTitle}>
        <FormattedHTMLMessage id={`metrics.${titleKey}`} />
      </p>
      <span className={contentClass}>
        {numberWithSpaces(value)}
        {currency}
      </span>
    </GeneralBlock>
  );
};

export default MetricBlock;
