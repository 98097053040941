import axios from 'axios';
import { RAG_ROUTE } from 'constants/routes';

class AIRAGApi {
  async uploadFiles(files, companyName, programName, isCommon, fileName, userEmail) {
    const formData = new FormData();
    const useAnthopic = process.env.REACT_APP_RAG_USE_ANTHROPIC_CONVERT||'False';

    files?.forEach((file, index) => {
      if (file) {
        formData.append('file', file);
        formData.append('filename', fileName);
        formData.append('output_path', isCommon ? `common` : `${companyName}/${programName}`);
        formData.append('company_name', companyName);
        formData.append('program_name', programName);
        formData.append('tools_file_name', `${programName}Tools`);
        formData.append('use_anthropic_convert', useAnthopic);  
        formData.append('user_email', userEmail);  
      }
    });

    try {
      const response = await axios.post(RAG_ROUTE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error uploading files:', error);
      throw error;
    }
  }
}

export default new AIRAGApi();
