import React from 'react';

import { DynamicFormattedMessage } from 'components/atoms/ui/DynamicFormattedMessage';
import { HTML_TAGS } from 'constants/general';
import { numberWithSpaces } from 'utils/general';
import { NUMBER } from 'constants/validation';
import { AMOUNT_OF_DECLARATIONS } from 'constants/wall/dashboard';

import coreStyle from 'sass-boilerplate/stylesheets/style.module.scss';
import componentStyle from 'sass-boilerplate/stylesheets/components/wall/Dashboard.module.scss';

/**
 * Organism component used to render dashboard block
 * @param kpi
 * @param selectKpi
 * @param kpiData
 * @param selectedKpi
 * @param isAdmin
 * @constructor
 */
const DashboardBlock = ({ kpi, selectKpi, kpiData, selectedKpi, isAdmin }) => {
  const keyPrefix = 'wall.dashboard.';
  const subtitlePrefix = keyPrefix + 'subtitle.';

  const { withSecondaryColor, withGrayLightAccentColor } = coreStyle;
  const { dashboardKpi, dashboardKpiTitle, dashboardKpiWidth } = componentStyle;

  return (
    <div
      className={`${dashboardKpi} ${kpi === selectedKpi ? withSecondaryColor : withGrayLightAccentColor} ${
        !isAdmin ? dashboardKpiWidth : ''
      }`}
      onClick={selectKpi}
    >
      <DynamicFormattedMessage className={dashboardKpiTitle} tag={HTML_TAGS.P} id={`${keyPrefix}${kpi}`} />
      <h3>
        {numberWithSpaces(kpiData[kpi] || 0)}
        {typeof kpiData[kpi] === NUMBER && kpi === AMOUNT_OF_DECLARATIONS && (
          <DynamicFormattedMessage tag={HTML_TAGS.SPAN} id={'label.euro'} />
        )}
      </h3>
      <DynamicFormattedMessage tag={HTML_TAGS.P} id={`${subtitlePrefix}${kpi}`} />
    </div>
  );
};

export default DashboardBlock;
