import React, { FC } from 'react';

/**
 * Pricing page template component
 *
 * @constructor
 */
const PricingPage: FC = () => {
  return <div>Pricing page</div>;
};

export default PricingPage;
