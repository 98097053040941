export enum EMAIL_CAMPAIGNS_SORTING {
  ID = 'id',
  NAME = 'name',
  PROGRAM = 'programName',
  RECIPIENTS = 'emailsSent',
  STATUS = 'status'
}

export enum SORTING_USER_LISTS {
  NAME = 'name',
  PROGRAM = 'programName',
  RECIPIENTS = 'total',
  CREATED_AT = 'createdAt'
}

export enum SORTING_CAMPAIGN_RECIPIENTS_LISTS {
  NAME = 'name',
  RECIPIENTS = 'total',
  CREATED_AT = 'createdAt'
}

export enum SORTING_CREATE_USER_LIST {
  LAST_NAME = 'lastName',
  ID = 'id',
  FIRST_NAME = 'firstName',
  EMAIL = 'email'
}
