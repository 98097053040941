export const DEFAULT = 'default';
export const BUTTON_TYPES = {
  DEFAULT: 'default',
  DELETE: 'delete',
  BACK: 'back',
  FORMATTED: 'formatted',
  SWITCH: 'switch',
  CLOSE: 'close',
  SUBMIT_FORM: 'submit form',
  DOWNLOAD: 'download'
};

export const ERROR_TYPES = {
  DISPLAY: 'display',
  LINE: 'line',
  LIST_TITLE: 'list title'
};

export const HEADING_TYPE = {
  DEFAULT: 'default',
  ANIMATED: 'animated'
};

export const LOGO_URL = {
  LOGO_1: 'logo 1',
  LOGO_2: 'logo 2'
};

export const FOOTER_TYPES = {
  LINK_GROUP_COLUMN: 'link group column',
  LINKS_GROUP: 'links group',
  LINKS_SECTION_PAGE: 'links section page',
  LOGO_GROUP: 'logo group',
  SOCIAL_LINK_LIST: 'social link list'
};

export const FORMS_TYPES = {
  TAILORED: 'tailored',
  RESELLER: 'reseller',
  CONTACT_FORM: 'contact form',
  CREATE_ACCOUNT_FORM: 'create account form'
};

export const FORM_FIELDS_TYPES = {
  DEFAULT: 'default',
  DATE_PICKER: 'date picker',
  DROPDOWN: 'dropdown',
  DYNAMIC_DATE_PICKER: 'dynamic date picker',
  EXTENDED_INPUT_FIELD: 'extended input field'
};
